"use client";
import React, { useState, useEffect, useRef } from "react";
import { Navbar as MaterialNavbar, Collapse } from "@material-tailwind/react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";

/* function to make navbar hidden on scroll down and visible on scroll up */
function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState("up");

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
}

export default function CustomNavbar() {
  const scrollDirection = useScrollDirection();
  const pathname = usePathname();
  const [showButton, setShowButton] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const navInView = () => {
      window.pageYOffset > 10 ? setShowButton(true) : setShowButton(false);
    };
    window.addEventListener("scroll", navInView);
    return () => window.removeEventListener("scroll", navInView);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) setOpenNav(false);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let handler = (e: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
        setOpenNav(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <div
      ref={menuRef}
      style={{ boxShadow: "0px 4px 4px 0px rgba(210, 210, 210, 0.25)" }}
      className={`py-6 px-4 lg:px-8 lg:py-6 text-[#4d4c4c] sticky top-0 bg-white z-50 w-full ${
        showButton ? "border-b-2 border-[#2F479E]" : ""
      }`}
    >
      <div
        className={`flex items-center text-xl justify-between lg:justify-center w-full px-4`}
      >
        <div className="hidden lg:flex items-center w-full justify-around 2xl:w-[70%]">
          <Link
            href="/"
            className={`mb-2 text-[#2F479E] text-xl font-bold LinkItem ${
              pathname === "/" ? "active" : ""
            }`}
            onClick={() => setOpenNav(false)}
          >
            Accueil
          </Link>
          <Link
            href="/about"
            className={`mb-2 text-[#2F479E] text-xl font-bold LinkItem ${
              pathname === "/about" ? "active" : ""
            }`}
            onClick={() => setOpenNav(false)}
          >
            À propos de nous
          </Link>
          <Image
            src="/assets/logo.png"
            alt="Logo"
            height={58}
            width={312}
            className="h-[58px] w-[300px]"
            priority
            style={{
              width: "auto",
              height: "auto",
              maxHeight: "100%",
              maxWidth: "100%",
            }}
          />
          <Link
            href="/servicepage"
            className={`mb-2 text-[#2F479E] text-xl font-bold LinkItem ${
              pathname === "/servicepage" ? "active" : ""
            }`}
            onClick={() => setOpenNav(false)}
          >
            Services
          </Link>
          <div className="flex gap-4 items-center justify-center">
            <Link
              href="/contact"
              className={`mb-2 text-[#2F479E] text-xl font-bold LinkItem ${
                pathname === "/contact" ? "active" : ""
              }`}
              onClick={() => setOpenNav(false)}
            >
              Contact
            </Link>
          </div>
          <Link
            href={`tel:+33189480112`} // Use 'tel:' protocol
            className="text-white bg-[#096BD8] hover:bg-white hover:text-[#096BD8] text-sm font-bold border-2 border-[#096BD8] rounded-xl p-3"
          >
            +33 189480112
          </Link>
        </div>

        <div className="w-full lg:hidden flex justify-between items-center p-2">
          <Image
            src="/assets/logo.png"
            alt="Logo"
            height={80}
            width={150}
            className="block lg:hidden w-[150px] min-sm:ml-24 aspect-auto"
            style={{
              width: "auto",
              height: "auto",
              maxHeight: "100%",
              maxWidth: "100%",
            }}
          />
          <button
            className="h-6 w-6 hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            onClick={() => setOpenNav(!openNav)}
            aria-label={openNav ? "Close navigation menu" : "Open navigation menu"}

          >
            {openNav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6 -translate-x-3 -translate-y-3"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 -translate-x-3 -translate-y-3"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>
        </div>
      </div>

      <Collapse open={openNav}>
        <div
          className={`mx-6 py-2 lg:hidden ${
            openNav ? "" : "hidden"
          } flex flex-col items-center gap-5 justify-normal pt-20`}
        >
          <Link
            href="/"
            className="mb-2 text-[#2F479E] text-xl font-bold"
            onClick={() => setOpenNav(false)}
          >
            Home
          </Link>
          <Link
            href="/about"
            className="mb-2 text-[#2F479E] text-xl font-bold"
            onClick={() => setOpenNav(false)}
          >
            À propos de nous
          </Link>

          <Link
            href="/servicepage"
            className="mb-2 text-[#2F479E] text-xl font-bold"
            onClick={() => setOpenNav(false)}
          >
            Services
          </Link>
          <Link
            href="/contact"
            className="mb-2 text-[#2F479E] text-xl font-bold"
            onClick={() => setOpenNav(false)}
          >
            Contact
          </Link>
          <Link
            href={`tel:+33189480112`} // Use 'tel:' protocol
            className="text-white bg-[#096BD8] hover:bg-white hover:text-[#096BD8] text-sm font-bold border-2 border-[#096BD8] rounded-xl p-3"
          >
            +33 189480112
          </Link>
        </div>
      </Collapse>
    </div>
  );
}
